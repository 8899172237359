"use client";

import React from "react";
import { Layout, Button, Typography } from "antd";

import { useUserContext } from "@/contexts/user.context";
import { App } from "antd";

import "@/config/auth.config";

const { Title, Paragraph } = Typography;
const { Content } = Layout;

export default function Root() {
  const { handleLogin } = useUserContext();
  return (
    <App>
      <Content
        style={{
          minHeight: "100vh",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}>
        <Title level={3} className='text-[#361272] text-center'>
          Smiles come easy with Dezy
        </Title>
        <Paragraph className='text-center'>
          The Perfect Smile correction, <br />
          from the comfort of your Home
        </Paragraph>
        <div className="flex items-center justify-center h-screen">
                  <Link
                    className="text-xl"
                    href={`https://${process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? 'neo.dezy.com' : 'neodev.dezy.com'}`}
                    
                  >
                    Please Use Neo CRM. Click Here to Proceed.
                  </Link>
                </div>
        {/* <Button
          type='default'
          size='large'
          shape='round'
          onClick={handleLogin}
          style={{
            backgroundColor: "#AF78FE",
            color: "white",
          }}>
          Login with Google
        </Button> */}
      </Content>
    </App>
  );
}
